import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Get } from 'webh5frame'
import Editor from 'md-editor-rt'
import Images from '../../images/index'
import { meanuList, componentList } from './data'
import 'md-editor-rt/lib/style.css'

function WuiDoc() {
	let [isOpen, setIsOpen] = useState([])

	let [refresh, setRefresh] = useState(false)

	useEffect(() => {
		refresh && setTimeout(() => setRefresh(false))
	}, [refresh])

	const navigate = useNavigate()
	const isClient = typeof window === 'object'
	const lastWidth = useRef()

	let initTheme = localStorage.getItem('theme') == null ? 'light' : localStorage.getItem('theme')

	let [themes, setThemes] = useState(initTheme)

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined
		}
	}

	const [windowSize, setWindowSize] = useState(getSize)

	useEffect(() => {
		if (!isClient) { return false } //Exit if not user/browser

		function handleResize() {
			if (window?.innerWidth !== lastWidth.current) {
				const width = getSize()
				lastWidth.current = width
				setWindowSize(width)
			}
		}

		window.addEventListener('resize', handleResize)
	})

	useEffect(() => {
		let div1 = document.getElementsByClassName('hoverDiv1')
		let div2 = document.getElementsByClassName('hoverDiv2')

		for (let i = 0; i < div1.length; i++) {
			div1[i].onmouseover = () => {
				if (themes == 'light') {
					div1[i].style.background = 'rgba(0,0,0,.06)'
				} else {
					div1[i].style.background = 'rgba(255,255,255,.1)'
				}
			}

			div1[i].onmouseout = () => {
				div1[i].style.background = ''
			}
		}

		for (let i = 0; i < div2.length; i++) {
			div2[i].onmouseover = () => {
				if (themes == 'light') {
					div2[i].style.background = 'rgba(0,0,0,.06)'
				} else {
					div2[i].style.background = 'rgba(255,255,255,.1)'
				}
			}

			div2[i].onmouseout = () => {
				div2[i].style.background = ''
			}
		}

	})

	let [websiteName, setWebsiteName] = useState('')

	useEffect(() => {
		Get(
			'/graphql',
			null,
			{
				table_name: 'config',
				search_field: `{
					id,
					website_name,
					record_number
        }`
			},
			(e) => {
				if (e.data) {
					setWebsiteName(e.data.config[0].website_name)
				}
			}
		)
	}, [websiteName])

	let [currentTab, setCurrentTab] = useState([0, 0])
	let [dockey, setDocKey] = useState(1)

	return (
		<div
			style={{
				width: '100%',
				margin: '0 auto',
				maxWidth: 1920,
				height: window.innerHeight,
				overflow: 'hidden'
			}}>
			{/* position */}
			<div id="container" style={{ display: 'none' }}></div>
			{/* nav */}
			<div
				style={{
					width: '100%',
					height: 60,
					background: '#20232a',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}>
				<div
					className="loader font2"
					onClick={() => {
						localStorage.removeItem('currentArticle')
						navigate('/')
					}}
					style={{
						cursor: 'pointer',
						marginLeft: 30,
						color: '#e91e63'
					}}>
					{
						websiteName.split('').map((i, n) => {
							return (
								<span key={n} className={`span${n % 6}`}>{i}</span>
							)
						})
					}
				</div>
				<div className="box">
					<div
						style={{
							display: 'none',
							position: 'relative',
							zIndex: 999,
							height: 60,
							marginRight: 60,
							fontSize: 44
						}}
						className="render">
					</div>
				</div>
				<div
					onClick={() => {
						if (themes === 'light') {
							setThemes(themes = 'dark')
							localStorage.setItem('theme', 'dark')
						} else {
							setThemes(themes = 'light')
							localStorage.setItem('theme', 'light')
						}
					}}
					style={{
						display: 'flex',
						fontSize: 26,
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
						zIndex: 999999,
						position: 'absolute',
						top: 20,
						right: 20,
						width: 26,
						height: 26,
						color: '#fff'
					}}>
					<img
						src={themes === 'light' ? Images.black.default : Images.sun.default}
						width={26}
						height={26} />
				</div>
			</div>
			{/* content */}
			<div
				style={{
					display: 'flex',
					width: '100%',
					height: window.innerHeight - 60,
					background: '#ececec'
				}}>
				{/* left */}
				<div
					style={{
						overflowX: 'hidden',
						overflowY: 'scroll',
						width: 300,
						height: '100%',
						background: themes === 'light' ? '#fff' : '#000',
						color: themes === 'light' ? '#000' : '#fff'
					}}>
					{
						meanuList.map((i, n) => {
							return (
								<div key={n}>
									<div
										onClick={() => {
											if (isOpen[n]) {
												isOpen[n] = false
												setIsOpen(isOpen)
												setRefresh(true)
											} else {
												isOpen[n] = true
												setIsOpen(isOpen)
												setRefresh(true)
											}
										}}
										className="hoverDiv1"
										style={{
											cursor: 'pointer',
											position: 'relative',
											width: '100%',
											height: 32,
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
										}}>
										<span
											style={{
												color: '#767676',
												marginLeft: 20,
												fontSize: 15,
												fontWeight: 500
											}}>
											{i.title}
										</span>
										{isOpen[n]}
										<img
											style={{
												position: 'absolute',
												right: 40,
											}}
											src={
												isOpen[n] ? Images.down.default :
													Images.right.default}
											width={12}
											height={12}
											alt="" />
									</div>
									{
										i.itemArr.map((ii, nn) => {
											return (
												<div
													key={nn}
													style={{
														background: currentTab[1] == nn && currentTab[0] == n ? themes == 'light' ? 'rgba(0,0,0,.06)' : 'rgba(255,255,255,.1)' : ''
													}}>
													<div
														className='hoverDiv2'
														onClick={() => {
															setDocKey(ii.key)
															setCurrentTab([n, nn])
														}}
														key={nn}
														style={{
															display: isOpen[n] ? 'flex' : 'none',
															alignItems: 'center',
															justifyContent: 'flex-start',
															cursor: 'pointer',
															width: '100%',
															height: 28,
														}}>
														<span
															style={{
																color: themes === 'light' ? '#1c1e21' : '#f5f6f7',
																marginLeft: 20,
																fontSize: 13
															}}>
															{ii.title}
														</span>
													</div>
												</div>
											)
										})
									}
								</div>
							)
						})
					}
				</div>
				{/* content */}
				<div
					style={{
						position: 'absolute',
						right: window.innerWidth > 1919 ? (window.innerWidth - 1920) / 2 : -20,
						width: window.innerWidth > 1919 ? (1920 - 260) : (window.innerWidth - 260),
						height: window.innerHeight - 60,
						background: themes === 'light' ? '#fff' : '#000',
						borderLeft: themes == 'light' ? '1px solid #ececec' : '1px solid #474748'
					}}>
					<div
						style={{
							height: window.innerHeight - 90,
							overflowY: 'scroll',
							padding: 20,
							paddingTop: 0
						}}>
						<h3 style={{ color: themes === 'light' ? '#000' : '#fff' }}>
							{componentList[dockey - 1].name}
						</h3>
						<div
							style={{
								color: themes === 'light' ? '#333' : '#fff',
								fontSize: 12
							}}>
							{componentList[dockey - 1].subtitle}
						</div>
						<h3
							style={{
								display: componentList[dockey - 1].useArr == '' ? 'none' : 'block',
								color: themes === 'light' ? '#000' : '#fff'
							}}>
							{'何时使用'}
						</h3>
						<div>
							{
								componentList[dockey - 1].useArr.map((i, n) => {
									return (
										<div
											key={n}
											style={{
												color: themes === 'light' ? '#333' : '#fff',
												fontSize: 12,
												marginBottom: 3
											}}>
											{i}
										</div>
									)
								})
							}
						</div>
						<h3
							style={{
								display: componentList[dockey - 1].codename == '' ? 'none' : 'block',
								color: themes === 'light' ? '#000' : '#fff'
							}}>
							{'代码演示'}
						</h3>
						<div
							style={{
								position: 'relative',
								display: componentList[dockey - 1].codename == '' ? 'none' : 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}>
							<Editor
								theme={themes}
								style={{
									width: window.innerWidth > 1919 ? `${(1920 - 500) * 100 / 1920}%` : `${(window.innerWidth - 500) * 100 / window.innerWidth}%`,
									height: 700
								}}
								previewTheme={'vuepress'}
								previewOnly
								modelValue={`
	import React from 'react'
	import { ${componentList[dockey - 1].codename} } from 'react-native-wui'
	import { View } from 'react-native'

	class Page extend React.Component {
		constructor(props) {
			super(props)
			this.state = {
			}
		}

		render() {
			return(
				<View>
					<${componentList[dockey - 1].codename} />
				</View>
			)
		}
	}

	export default Page



								`
								}
							/>
							<div
								style={{
									position: 'absolute',
									right: 400,
									top: 0,
									width: 20,
									height: 700,
									background: themes === 'light' ? '#fff' : '#000'
								}}>

							</div>
							<div
								style={{
									zIndex: 99,
									width: 320,
									height: 700,
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center'
								}}>
								<img
									style={{
										marginRight: 60,
										border: themes === 'light' ? "1px solid #f2f2f2" : ""
									}}
									height={674}
									width={320}
									src={componentList[dockey - 1].codeImage.default}>

								</img>
							</div>
						</div>

						<h3
							style={{
								display: componentList[dockey - 1].apiArr == '' ? 'none' : 'block',
								color: themes === 'light' ? '#000' : '#fff'
							}}>
							{'API'}
						</h3>
						<div
							style={{
								width: window.innerWidth > 1919 ? 1920 - 330 : window.innerWidth - 330,
								border: themes === 'light' ? '1px solid #333' : '1px solid #fff',
								borderBottom: '0px solid #fff',
								display: componentList[dockey - 1].apiArr == '' ? 'none' : 'block',
							}}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									height: 46,
									borderBottom: themes === 'light' ? '1px solid #333' : '1px solid #fff'
								}}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										width: '20%',
										height: '100%',
										fontWeight: '800',
										color: themes === 'light' ? '#333' : '#fff',
										paddingLeft: 20
									}}>
									参数
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										width: '50%',
										height: '100%',
										fontWeight: '800',
										color: themes === 'light' ? '#333' : '#fff',
										paddingLeft: 20,
										borderLeft: themes === 'light' ? '1px solid #333' : '1px solid #fff',
										borderRight: themes === 'light' ? '1px solid #333' : '1px solid #fff'
									}}>
									说明
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										width: '20%',
										height: '100%',
										fontWeight: '800',
										color: themes === 'light' ? '#333' : '#fff',
										paddingLeft: 20
									}}>
									类型
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										width: '10%',
										height: '100%',
										fontWeight: '800',
										color: themes === 'light' ? '#333' : '#fff',
										paddingLeft: 20,
										borderLeft: themes === 'light' ? '1px solid #333' : '1px solid #fff'
									}}>
									默认值
								</div>
							</div>
							{
								componentList[dockey - 1].apiArr.map((i, n) => {
									return (
										<div
											key={n}
											style={{
												display: 'flex',
												justifyContent: 'flex-start',
												alignItems: 'center',
												height: 46,
												borderBottom: themes === 'light' ? '1px solid #333' : '1px solid #fff'
											}}>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-start',
													alignItems: 'center',
													width: '20%',
													height: '100%',
													fontSize: 14,
													color: themes === 'light' ? '#333' : '#fff',
													paddingLeft: 20
												}}>
												{i.parameter}
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-start',
													alignItems: 'center',
													width: '50%',
													height: '100%',
													fontSize: 14,
													color: themes === 'light' ? '#333' : '#fff',
													paddingLeft: 20,
													borderLeft: themes === 'light' ? '1px solid #333' : '1px solid #fff',
													borderRight: themes === 'light' ? '1px solid #333' : '1px solid #fff'
												}}>
												{i.explain}
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-start',
													alignItems: 'center',
													width: '20%',
													height: '100%',
													fontSize: 14,
													color: themes === 'light' ? '#333' : '#fff',
													paddingLeft: 20
												}}>
												{i.type}
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-start',
													alignItems: 'center',
													width: '10%',
													height: '100%',
													fontSize: 14,
													color: themes === 'light' ? '#333' : '#fff',
													paddingLeft: 20,
													borderLeft: themes === 'light' ? '1px solid #333' : '1px solid #fff'
												}}>
												{i.default}
											</div>
										</div>
									)
								})
							}
						</div>
						<h3
							style={{
								display: componentList[dockey - 1].methodArr == '' ? 'none' : 'block',
								color: themes === 'light' ? '#000' : '#fff'
							}}>
							{'方法'}
						</h3>
						<div
							style={{
								width: window.innerWidth > 1919 ? 1920 - 330 : window.innerWidth - 330,
								border: themes === 'light' ? '1px solid #333' : '1px solid #fff',
								borderBottom: '0px solid #fff',
								display: componentList[dockey - 1].methodArr == '' ? 'none' : 'block',
							}}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									height: 46,
									borderBottom: themes === 'light' ? '1px solid #333' : '1px solid #fff'
								}}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										width: '20%',
										height: '100%',
										fontWeight: '800',
										color: themes === 'light' ? '#333' : '#fff',
										paddingLeft: 20
									}}>
									参数
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										width: '50%',
										height: '100%',
										fontWeight: '800',
										color: themes === 'light' ? '#333' : '#fff',
										paddingLeft: 20,
										borderLeft: themes === 'light' ? '1px solid #333' : '1px solid #fff',
										borderRight: themes === 'light' ? '1px solid #333' : '1px solid #fff'
									}}>
									说明
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										width: '20%',
										height: '100%',
										fontWeight: '800',
										color: themes === 'light' ? '#333' : '#fff',
										paddingLeft: 20
									}}>
									类型
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										width: '10%',
										height: '100%',
										fontWeight: '800',
										color: themes === 'light' ? '#333' : '#fff',
										paddingLeft: 20,
										borderLeft: themes === 'light' ? '1px solid #333' : '1px solid #fff'
									}}>
									默认值
								</div>
							</div>
							{
								componentList[dockey - 1].methodArr.map((i, n) => {
									return (
										<div
											key={n}
											style={{
												display: 'flex',
												justifyContent: 'flex-start',
												alignItems: 'center',
												height: 46,
												borderBottom: themes === 'light' ? '1px solid #333' : '1px solid #fff'
											}}>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-start',
													alignItems: 'center',
													width: '20%',
													height: '100%',
													fontSize: 14,
													color: themes === 'light' ? '#333' : '#fff',
													paddingLeft: 20
												}}>
												{i.parameter}
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-start',
													alignItems: 'center',
													width: '50%',
													height: '100%',
													fontSize: 14,
													color: themes === 'light' ? '#333' : '#fff',
													paddingLeft: 20,
													borderLeft: themes === 'light' ? '1px solid #333' : '1px solid #fff',
													borderRight: themes === 'light' ? '1px solid #333' : '1px solid #fff'
												}}>
												{i.explain}
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-start',
													alignItems: 'center',
													width: '20%',
													height: '100%',
													fontSize: 14,
													color: themes === 'light' ? '#333' : '#fff',
													paddingLeft: 20
												}}>
												{i.type}
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-start',
													alignItems: 'center',
													width: '10%',
													height: '100%',
													fontSize: 14,
													color: themes === 'light' ? '#333' : '#fff',
													paddingLeft: 20,
													borderLeft: themes === 'light' ? '1px solid #333' : '1px solid #fff'
												}}>
												{i.default}
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WuiDoc