import React from "react"
import {
	Route,
	Routes,
	BrowserRouter
} from "react-router-dom"
import {
	Home,
	Article,
	WuiDoc,
	About
} from './pages/index'

export default function MyRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route exact path='/article' element={<Article />} />
				<Route exact path='/wuidoc' element={<WuiDoc />} />
				<Route exact path='/about' element={<About />} />
			</Routes>
		</BrowserRouter>
	)
}