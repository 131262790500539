import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Editor from 'md-editor-rt'
import { Get } from 'webh5frame'
import Images from '../../images/index'
import 'md-editor-rt/lib/style.css'

function Article() {
	let type = 1
	let typeIndex = window.location.href.indexOf('type')
	let articleIndex = window.location.href.indexOf('essay')

	let [currentArticle, setCurrentArticle] = useState(localStorage.getItem('currentArticle'))

	function getArticle(articleID) {
		Get(
			'/graphql',
			null,
			{
				table_name: 'article',
				search_field: `(
					where:{id:{_eq:`+ Number(articleID) + `}}
				){
					id,
					name,
					content
				}`
			},
			(e) => {
				if (e.data) {
					// let content = e.data.article[0].content.replace(new RegExp(`/api/v1/download[^\n\r\u2028\u2029]static`,"gm"),"")
					let content = e.data.article[0].content
					setArticle(content)
				}
			}
		)
	}

	const [navList, setNavList] = useState([])

	useEffect(() => {
		if (navList.length !== 0) { return }
		Get(
			'/graphql',
			null,
			{
				table_name: 'nav',
				search_field: `{
					id,
					name
        }`
			},
			(e) => {
				if (e.data) {
					setNavList(e.data.nav)
				}
			}
		)
	}, [navList])

	if (articleIndex == -1) {
		type = window.location.href.slice(typeIndex + 5)
	} else {
		type = window.location.href.slice(typeIndex + 5, articleIndex - 1)
		localStorage.setItem('currentArticle', window.location.href.slice(articleIndex + 6))
		getArticle(window.location.href.slice(articleIndex + 6))
	}

	let [subNav, setSubNav] = useState('')

	let [subNavList, setSubNavList] = useState([])

	let [article, setArticle] = useState('')

	let [isOpen, setIsOpen] = useState([])

	let [refresh, setRefresh] = useState(false)

	useEffect(() => {
		refresh && setTimeout(() => setRefresh(false))
	}, [refresh])

	useEffect(() => {
		if (subNav !== '') { return }
		Get(
			'/graphql',
			null,
			{
				table_name: 'nav',
				search_field: `(
					where:{id:{_eq:`+ Number(type) + `}}
				){
					id,
					name,
					sub_nav{
						id,
						name,
						article{
							id,
							name,
							content
						}
					}
				}`
			},
			(e) => {
				if (e.data) {
					let openArr = e.data.nav[0].sub_nav
					let isopen = []

					for (let i = 0; i < openArr.length; i++) {
						let article = openArr[i].article
						for (let j = 0; j < article.length; j++) {

							if (articleIndex !== -1) {
								if (article[j].id == window.location.href.slice(articleIndex + 6)) {
									isopen.push(true)
									setCurrentArticle(article[j].id)
								}
							}

							if (articleIndex == -1 && localStorage.getItem('currentArticle') == null && i == 0 && j == 0) {
								isopen.push(true)
								setCurrentArticle(article[j].id)
							}

							if (articleIndex == -1 && localStorage.getItem('currentArticle') !== null) {
								if (article[j].id == localStorage.getItem('currentArticle')) {
									isopen.push(true)
									setCurrentArticle(article[j].id)
								}
							}
						}
						if (isopen[i] !== true) {
							isopen.push(false)
						}
					}

					setIsOpen(isopen)

					// document.title = e.data.nav[0].name

					setSubNav(e.data.nav[0].name)
					setSubNavList(e.data.nav[0].sub_nav)

					if (localStorage.getItem('currentArticle') == null) {
						// let content = e.data.nav[0].sub_nav[0].article[0].content.replace(new RegExp(`/api/v1/download[^\n\r\u2028\u2029]static`,"gm"),"")
						let content = e.data.nav[0].sub_nav[0].article[0].content
						setArticle(content)
					}

					if (articleIndex == -1 && localStorage.getItem('currentArticle') == null) {
						// let content = e.data.nav[0].sub_nav[0].article[0].content.replace(new RegExp(`/api/v1/download[^\n\r\u2028\u2029]static`,"gm"),"")
						let content = e.data.nav[0].sub_nav[0].article[0].content
						setArticle(content)
					}

					if (articleIndex == -1 && localStorage.getItem('currentArticle') !== null) {
						getArticle(localStorage.getItem('currentArticle'))
					}

				}
			}
		)
	})

	function pageChange() {
		let types = 1
		let typeIndex = window.location.href.indexOf('type')
		let articleIndex = window.location.href.indexOf('essay')
		if (articleIndex == -1) {
			types = window.location.href.slice(typeIndex + 5)
		} else {
			types = window.location.href.slice(typeIndex + 5, articleIndex - 1)
			localStorage.setItem('currentArticle', window.location.href.slice(articleIndex + 6))
			getArticle(window.location.href.slice(articleIndex + 6))
		}
		setSubNav('')
	}

	const navigate = useNavigate()
	const isClient = typeof window === 'object'
	const lastWidth = useRef()

	let initTheme = localStorage.getItem('theme') == null ? 'light' : localStorage.getItem('theme')

	let [themes, setThemes] = useState(initTheme)

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined
		}
	}

	const [windowSize, setWindowSize] = useState(getSize)


	useEffect(() => {
		if (!isClient) { return false } //Exit if not user/browser

		function handleResize() {
			if (window?.innerWidth !== lastWidth.current) {
				const width = getSize()
				lastWidth.current = width
				setWindowSize(width)
			}
		}

		window.addEventListener('resize', handleResize)
	})

	useEffect(() => {
		let div1 = document.getElementsByClassName('hoverDiv1')
		let div2 = document.getElementsByClassName('hoverDiv2')

		for (let i = 0; i < div1.length; i++) {
			div1[i].onmouseover = () => {
				if (themes == 'light') {
					div1[i].style.background = 'rgba(0,0,0,.06)'
				} else {
					div1[i].style.background = 'rgba(255,255,255,.1)'
				}
			}

			div1[i].onmouseout = () => {
				div1[i].style.background = ''
			}
		}

		for (let i = 0; i < div2.length; i++) {
			div2[i].onmouseover = () => {
				if (themes == 'light') {
					div2[i].style.background = 'rgba(0,0,0,.06)'
				} else {
					div2[i].style.background = 'rgba(255,255,255,.1)'
				}
			}

			div2[i].onmouseout = () => {
				div2[i].style.background = ''
			}
		}

	})

	useEffect(() => {
		var box = document.querySelector('.box').textContent,
			rs = document.querySelector('.render'),
			f = [
				'arial', 'verdana', 'monospace',
				'consolas', 'impact', 'helveltica'
			],
			c = [
				'1ABC9C', '3498DB', '34495E', 'E67E22',
				'E74C3C', '2ECC71', 'E74C3C', '95A5A6', 'D35400'
			];
		var out = '';
		for (var i = 0; i < box.length; i++) {
			// Random array fonts
			var r = f[Math.floor(Math.random() * f.length)],
				// Random array colors
				sh = c[Math.floor(Math.random() * c.length)],
				st = 'color:#' + sh +
					';font-family: ' + r +
					';text-shadow:0px 1px 0px #' + sh + ',0px 2px 0px #' + sh + ',0px 3px 0px #' + sh + ',0px 4px 0px #' + sh + ', 0px 5px 0px  #' + sh + ',0px 6px 0px #' + sh + ', 0px 7px 0px #' + sh + ',0px 8px 7px #' + sh;
			out += '<span style="' + st + '">' + box[i] + '</span>';
		}
		rs.innerHTML = out;
	})

	useEffect(() => {
		let isrun = false
		if (isrun) { return }
		let pre = document.getElementsByTagName('pre')
		for (let i = 0; i < pre.length; i++) {
			if (window.innerWidth > 1919) {
				pre[i].style.width = 1920 - 580 + 'px'
			} else {
				pre[i].style.width = window.innerWidth - 580 + 'px'
			}
		}

		let imgs = document.getElementsByTagName('figure')

		for (let i = 0; i < imgs.length; i++) {

			if (window.innerWidth > 1919) {
				imgs[i].children[0].style.width = 1920 - 580 + 'px'
			} else {
				imgs[i].children[0].style.width = window.innerWidth - 580 + 'px'
			}
		}

		isrun = true
	})

	let [linkNav, setLinkNav] = useState([])
	let [rightnav, setRightNav] = useState('')

	function scrollToTop() {
		let md = document.getElementById('md-preview')
		md.scrollTop = 0
	}

	let [websiteName, setWebsiteName] = useState('')

	useEffect(() => {
		Get(
			'/graphql',
			null,
			{
				table_name: 'config',
				search_field: `{
					id,
					website_name,
					record_number
        }`
			},
			(e) => {
				if (e.data) {
					setWebsiteName(e.data.config[0].website_name)
				}
			}
		)
	}, [websiteName])

	// Scroll Tag
	useEffect(() => {
		window.addEventListener('scroll', () => {
			let Tags = document.querySelectorAll('h1,h2,h3,h4,h5,h6,h7')
			let tag_arr = []
			for (let i = 0; i < Tags.length; i++) {
				tag_arr.push(Tags[i].offsetTop)
			}
			let scrolltop = document.getElementById('md-preview').scrollTop

			tag_arr.sort((a, b) => { return a - b; })

			if (scrolltop == 0) {
				setRightNav('')
			}

			for (let i = 0; i < Tags.length; i++) {
				if (i == 0) {
					if (Tags.length == 1) {
						if (0 < scrolltop) {
							setRightNav(0)
						}
					} else {
						if (0 < scrolltop && scrolltop < tag_arr[i + 1]) {
							setRightNav(0)
						}
					}
				} else if (i == Tags.length - 1) {
					if (tag_arr[i] < scrolltop) {
						setRightNav(i)
					}
				} else {
					if (tag_arr[i] < scrolltop && scrolltop < tag_arr[i + 1]) {
						setRightNav(i)
					}
				}
			}
		}, true)
	}, [])

	return (
		<div
			style={{
				width: '100%',
				margin: '0 auto',
				maxWidth: 1920,
				height: window.innerHeight,
				overflow: 'hidden'
			}}>
			{/* position */}
			<div id="container" style={{ display: 'none' }}></div>
			{/* nav */}
			<div
				style={{
					width: '100%',
					height: 60,
					background: '#20232a',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					position: 'fixed',
					maxWidth: 1920
				}}>
				<div
					className="loader font2"
					onClick={() => {
						localStorage.removeItem('currentArticle')
						navigate('/')
					}}
					style={{
						cursor: 'pointer',
						marginLeft: 30,
						color: '#e91e63'
					}}>
					{
						websiteName.split('').map((i, n) => {
							return (
								<span key={n} className={`span${n % 6}`}>{i}</span>
							)
						})
					}
				</div>
				<div className="box">
					<div
						style={{
							display: 'none',
							position: 'relative',
							zIndex: 999,
							height: 60,
							marginRight: 60,
							fontSize: 44
						}}
						className="render">
						{subNav}
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}>
					{
						navList.map((i, n) => {
							return (
								<div
									key={n}
									className={`font2`}
									onClick={() => {
										navigate(`/article?type=${i.id}`)
										pageChange()
										localStorage.removeItem('currentArticle')
									}}
									style={{
										paddingTop: 16,
										paddingBottom: 16,
										fontSize: 15,
										cursor: 'pointer',
										marginRight: 25,
										color: type == i.id ? '#FFF' : '#e91e63'
									}}>
									{
										i.name.split('').map((ii, nn) => {
											return (
												nn == 0 ?
													<span key={nn}>{ii}</span> :
													<span key={nn} className={`111span${nn + 1}`}>{ii}</span>
											)
										})
									}
								</div>
							)
						})
					}
					<div
						onClick={() => {
							if (themes === 'light') {
								setThemes(themes = 'dark')
								localStorage.setItem('theme', 'dark')
							} else {
								setThemes(themes = 'light')
								localStorage.setItem('theme', 'light')
							}
						}}
						style={{
							display: 'flex',
							fontSize: 26,
							justifyContent: 'center',
							alignItems: 'center',
							cursor: 'pointer',
							zIndex: 999999,
							width: 26,
							height: 26,
							color: '#fff',
							marginRight: 20
						}}>
						<img
							src={themes === 'light' ? Images.black.default : Images.sun.default}
							width={26}
							height={26} />
					</div>
				</div>
			</div>
			{/* content */}
			<div
				style={{
					display: 'flex',
					height: window.innerHeight - 60,
					background: '#ececec',
					position: 'fixed',
					top: 60
				}}>
				{/* left */}
				<div
					style={{
						overflowX: 'hidden',
						overflowY: 'scroll',
						width: 300,
						height: '100%',
						background: themes === 'light' ? '#fff' : '#000',
						color: themes === 'light' ? '#000' : '#fff'
					}}>
					{
						subNavList.map((i, n) => {
							return (
								<div key={n}>
									<div
										onClick={() => {
											if (isOpen[n]) {
												isOpen[n] = false
												setIsOpen(isOpen)
												setRefresh(true)
											} else {
												isOpen[n] = true
												setIsOpen(isOpen)
												setRefresh(true)
											}
										}}
										className="hoverDiv1"
										style={{
											cursor: 'pointer',
											position: 'relative',
											width: '100%',
											height: 32,
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
										}}>
										<span
											style={{
												color: '#767676',
												marginLeft: 20,
												fontSize: 15,
												fontWeight: 500
											}}>
											{i.name}
										</span>
										{isOpen[n]}
										<img
											style={{
												position: 'absolute',
												right: 40,
											}}
											src={
												isOpen[n] ? Images.down.default :
													Images.right.default}
											width={12}
											height={12}
											alt="" />
									</div>
									{
										i.article.map((ii, nn) => {
											return (
												<div
													key={nn}
													style={{
														background: currentArticle == ii.id ? themes == 'light' ? 'rgba(0,0,0,.06)' : 'rgba(255,255,255,.1)' : ''
													}}>
													<div
														className='hoverDiv2'
														onClick={() => {
															localStorage.setItem('currentArticle', ii.id)
															setCurrentArticle(ii.id)
															navigate(`/article?type=${type}&essay=${ii.id}`)
														}}
														key={nn}
														style={{
															display: isOpen[n] ? 'flex' : 'none',
															alignItems: 'center',
															justifyContent: 'flex-start',
															cursor: 'pointer',
															width: '100%',
															height: 28,
														}}>
														<span
															style={{
																color: themes === 'light' ? '#1c1e21' : '#f5f6f7',
																marginLeft: 20,
																fontSize: 13
															}}>
															{ii.name}
														</span>
													</div>
												</div>
											)
										})
									}
								</div>
							)
						})
					}
				</div>
				{/* content */}
				<div
					style={{
						position: 'fixed',
						right: window.innerWidth > 1919 ? (window.innerWidth - 1920 - 40) / 2 : -20,
						width: window.innerWidth > 1919 ? (1920 - 260) : (window.innerWidth - 260),
						height: window.innerHeight - 60,
						background: themes === 'light' ? '#fff' : '#000',
						borderLeft: themes == 'light' ? '1px solid #ececec' : '1px solid #474748'
					}}>
					<Editor
						theme={themes}
						style={{
							width: '100%',
							height: '100%',
							paddingLeft: 20,
							paddingTop: 0,
							paddingBottom: 10,
							paddingRight: window.innerWidth > 1919 ? 0 : 20
						}}
						previewTheme={'vuepress'}
						previewOnly
						modelValue={article}
						onGetCatalog={(e) => {
							setRightNav('')
							scrollToTop()
							setLinkNav(e)
						}}
					/>
					<div
						style={{
							display: linkNav.length == 0 ? 'none' : 'block',
							position: 'absolute',
							width: 260,
							right: 30,
							height: window.innerHeight - 140,
							top: 20,
							paddingTop: 20,
							paddingBottom: 20,
							borderLeft: themes == 'light' ? '1px solid #ececec' : '1px solid #474748',
							overflowY: 'scroll'
						}}>
						{
							linkNav.map((i, n) => {
								return (
									<div
										className='linktag'
										key={n}
										style={{
											color: rightnav === n ? '#00aeff' : themes === 'light' ? '#1c1e21' : '#f5f6f7',
											fontSize: 12,
											marginLeft: 12,
											marginBottom: 12,
											cursor: 'pointer',
											paddingRight: 20,
											paddingBottom: 6
										}}
										onClick={() => {
											setTimeout(() => {
												setRightNav(n)
											}, 300);
											let anchorElement = document.getElementById(`${i.text}`)
											anchorElement.scrollIntoView({ block: 'start', behavior: 'auto' })
										}}>
										{i.text}
									</div>
								)
							})
						}
					</div>
					<div
						style={{
							position: 'absolute',
							top: 0,
							right: 30,
							width: 20,
							height: window.innerHeight,
							background: themes == 'light' ? '#fff' : '#000',
							zIndex: 22
						}}>

					</div>
				</div>
			</div>
		</div>
	)
}

export default Article