import React, { useState, useEffect, useRef } from 'react'
import { Get } from 'webh5frame'
import { useNavigate } from "react-router-dom"
import TWEEN from '@tweenjs/tween.js'

function Home() {
	const fixValue = 0
	const navigate = useNavigate()
	const isClient = typeof window === 'object'
	const lastWidth = useRef()

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined
		}
	}

	const [windowSize, setWindowSize] = useState(getSize)

	const [navList, setNavList] = useState([])

	useEffect(() => {
		Get(
			'/graphql',
			null,
			{
				table_name: 'nav',
				search_field: `{
					id,
					name
        }`
			},
			(e) => {
				if (e.data) {
					setNavList(e.data.nav)
				}
			}
		)
	}, [navList])

	useEffect(() => {
		//Exit if not user/browser
		if (!isClient) { return false }

		function handleResize() {
			if (window?.innerWidth !== lastWidth.current) {
				const width = getSize()
				lastWidth.current = width
				setWindowSize(width)
			}
		}

		window.addEventListener('resize', handleResize)
	})

	useEffect(() => {
		var script = document.createElement('script');
		script.src = './pixijs/require.js';
		document.head.appendChild(script);

		var script1 = document.createElement('script');
		script1.src = './pixijs/nebulon.js';
		document.head.appendChild(script1);

		// init setting
		localStorage.removeItem('currentArticle')
	}, [fixValue])

	let [websiteName, setWebsiteName] = useState('')
	let [recordNumber, setRecordNumber] = useState('')
	let [leftText, setLeftText] = useState('')

	useEffect(() => {
		Get(
			'/graphql',
			null,
			{
				table_name: 'config',
				search_field: `{
					id,
					website_name,
					record_number,
					left_text
        }`
			},
			(e) => {
				if (e.data) {
					setWebsiteName(e.data.config[0].website_name)
					// document.title = e.data.config[0].website_name.toLowerCase()
					setRecordNumber(e.data.config[0].record_number)
					setLeftText(e.data.config[0].left_text)
				}
			}
		)
	}, [websiteName, recordNumber, leftText])

	// Leave the page Animation
	function leavePageAnimation(pageurl) {
		let page = document.getElementsByClassName('page')[0]
		var coords = { x: 1, y: 1 };
		var tween = new TWEEN.Tween(coords)
			.to({ x: 0, y: 0.9 }, 300)
			.easing(TWEEN.Easing.Quadratic.Out)
			.onUpdate(function () {
				page.style.setProperty('opacity', coords.x);
				page.style.setProperty('transform', 'scale(' + coords.y + ')');
			})
			.start();
		animate();
		function animate() {
			requestAnimationFrame(animate);
			TWEEN.update();
		}
		setTimeout(() => {
			navigate(pageurl);
		}, 300);
	}

	return (
		<div
			className='page'
			style={{
				width: window.innerWidth,
				height: window.innerHeight,
				position: 'fixed'
			}}>
			<div
				itemID='container'
				id='container'
				style={{
					position: 'absolute',
					width: window.innerWidth,
					height: window.innerHeight,
					zIndex: 0,
					background: 'rgba(0,0,0,.2)'
				}}>
			</div>
			<div
				style={{
					height: 60,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}>
				<div
					className="loader font2"
					style={{
						marginLeft: 30,
						color: '#e91e63'
					}}>
					{
						websiteName.split('').map((i, n) => {
							return (
								<span key={n} className={`span${n % 6}`}>{i}</span>
							)
						})
					}
				</div>

				<div
					style={{
						position: 'absolute',
						right: 5,
						display: 'flex'
					}}>
					{
						navList.map((i, n) => {
							return (
								<div
									key={n}>
									<div
										onClick={() => {
											leavePageAnimation(`/article?type=${i.id}`)
										}}
										className="font2"
										style={{
											fontSize: 15,
											cursor: 'pointer',
											marginRight: 25,
											color: '#e91e63'
										}}>
										{
											i.name.split('').map((ii, nn) => {
												return (
													nn == 0 ?
														<span key={nn}>{ii}</span> :
														<span key={nn} className={`111span${nn + 1}`}>{ii}</span>
												)
											})
										}
									</div>
								</div>
							)
						})
					}
					<div style={{ display: 'none' }}>
						<div
							onClick={() => {
								leavePageAnimation(`/about`)
							}}
							className="font2"
							style={{
								fontSize: 15,
								cursor: 'pointer',
								marginRight: 25,
								color: '#e91e63'
							}}>
							<span>About</span>
						</div>
					</div>
				</div>
			</div>
			<div
				onClick={() => {
					leavePageAnimation('/wuidoc')
				}}
				className="font2"
				style={{
					cursor: 'pointer',
					position: 'absolute',
					bottom: 20,
					left: 30,
					fontSize: 22,
					color: '#e91e63'
				}}>
				{
					leftText.split('').map((i, n) => {
						return (
							<span key={n} className={`111span${n % 6}`}>{i}</span>
						)
					})
				}
			</div>
			<a
				target={'_blank'}
				href='https://beian.miit.gov.cn/'
				className="font2"
				style={{
					position: 'absolute',
					textDecoration: 'none',
					bottom: 20,
					left: window.innerWidth / 2 - 64,
					fontSize: 14,
					color: '#e91e63'
				}}>
				{
					recordNumber.split('').map((i, n) => {
						return (
							<span key={n} className={`111span${n % 6}`}>{i}</span>
						)
					})
				}
			</a>
			<a
				target={'_blank'}
				className="font2"
				style={{
					position: 'absolute',
					textDecoration: 'none',
					bottom: 20,
					right: 30,
					fontSize: 14,
					color: '#e91e63'
				}}>
				version:1.0.1
			</a>
		</div>
	)
}

export default Home