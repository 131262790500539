import Images from '../../images/index'
// ## 标题导航 ##
const meanuList = [
	{
		title: '登陆注册',
		key: 'sub1',
		icontype: 'login',
		itemArr: [
			{
				title: '用户注册',
				key: 1
			},
			{
				title: '用户登陆',
				key: 2
			}
		]
	},
	{
		title: '标题导航',
		key: 'sub2',
		icontype: 'menu-unfold',
		itemArr: [
			{
				title: '顶部导航',
				key: 3
			},
			{
				title: '标题导航',
				key: 4
			},
			{
				title: '顶部搜索',
				key: 5
			},
			{
				title: '选项切换',
				key: 6
			},
			{
				title: '吸顶导航',
				key: 7
			}
		]
	},
	{
		title: '信息卡片',
		key: 'sub3',
		icontype: 'contacts',
		itemArr: [
			{
				title: '资料卡片',
				key: 8
			},
			{
				title: '图片卡片',
				key: 9
			},
			{
				title: '图片卡片',
				key: 10
			}
		]
	},
	{
		title: '列表评论',
		key: 'sub4',
		icontype: 'unordered-list',
		itemArr: [
			{
				title: '文章列表',
				key: 11
			},
			{
				title: '评论列表',
				key: 12
			}
		]
	},
	{
		title: '辅助工具',
		key: 'sub5',
		icontype: 'tool',
		itemArr: [
			{
				title: '加载提示',
				key: 13
			},
			{
				title: '弹框提示',
				key: 14
			},
			{
				title: '时间选择',
				key: 15
			},
			{
				title: '日期选择',
				key: 16
			},
			{
				title: '弹框日历',
				key: 17
			},
			{
				title: '分类选择',
				key: 18
			},
			{
				title: '滑动开关',
				key: 19
			},
			{
				title: '帮助中心',
				key: 20
			},
			{
				title: '人机验证',
				key: 21
			},
			{
				title: '进度展示',
				key: 22
			}
		]
	},
	{
		title: '商城组件',
		key: 'sub6',
		icontype: 'shop',
		itemArr: [
			{
				title: '数量选择',
				key: 23
			},
			{
				title: '下拉列表',
				key: 24
			},
			{
				title: '聊天消息',
				key: 25
			},
			{
				title: '验证计时',
				key: 26
			},
			{
				title: '支付密码',
				key: 27
			}
		]
	},
	{
		title: '关于',
		key: 'sub7',
		icontype: 'read',
		itemArr: [
			{
				title: '关于我们',
				key: 28
			}
		]
	}
]

// ## 组件介绍 ##
const componentList = [
	{
		name: 'Wregister 用户注册',
		subtitle: '用户注册，账号、密码、手机号码填写。',
		useArr: [
			'用于手机端App用户注册界面。',
			'支持用户输入手机号码、用户名、密码，用于注册用户信息。',
			'支持两次输入密码校验功能以及提示信息等。'
		],
		codename: 'Wregister',
		codeImage: Images.wregister,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'centerConStyle',
				explain: '中间容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'inputConStyle',
				explain: '输入框外侧容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'leftInputConStyle',
				explain: '输入框内部左侧容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'inputStyle',
				explain: '输入框样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'inputLeftImgStyle',
				explain: '输入框左侧容器图片样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '7',
				parameter: 'leftImgUrl',
				explain: '用户名输入框左侧容器图片地址设置',
				type: 'string',
				default: ''
			},
			{
				key: '8',
				parameter: 'leftImgUrlE',
				explain: 'Email输入框左侧容器图片地址设置',
				type: 'string',
				default: ''
			},
			{
				key: '9',
				parameter: 'leftImgUrlP',
				explain: '用户密码输入框左侧容器图片地址设置',
				type: 'string',
				default: ''
			},
			{
				key: '10',
				parameter: 'leftImgUrlT',
				explain: '用户电话输入框左侧容器图片地址设置',
				type: 'string',
				default: ''
			},
			{
				key: '11',
				parameter: 'submitConStyle',
				explain: '提交按钮样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '12',
				parameter: 'submitText',
				explain: '输入框样式设置',
				type: 'string、text',
				default: ''
			},
			{
				key: '13',
				parameter: 'submitTextStyle',
				explain: '提交按钮文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '14',
				parameter: 'bottomConStyle',
				explain: '底部容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '15',
				parameter: 'bottomTextStyle',
				explain: '底部容器字体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '16',
				parameter: 'bottomText',
				explain: '底部容器字体设置',
				type: 'string、number',
				default: ''
			},
			{
				key: '17',
				parameter: 'titleStyle',
				explain: '标题文字容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '18',
				parameter: 'titleTextStyle',
				explain: '标题文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '19',
				parameter: 'titles',
				explain: '标题文字设置',
				type: 'string | number',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'inputTextUsername',
				explain: '获取当前用户输入的用户名',
				type: 'function',
				default: ''
			},
			{
				key: '2',
				parameter: 'inputTextPassWord',
				explain: '获取当前用户输入的密码',
				type: 'function',
				default: ''
			},
			{
				key: '3',
				parameter: 'inputTextEmail',
				explain: '获取当前用户输入的Email',
				type: 'function',
				default: ''
			},
			{
				key: '4',
				parameter: 'inputTextPhoneNumber',
				explain: '获取当前用户输入的电话',
				type: 'function',
				default: ''
			},
			{
				key: '5',
				parameter: 'submitFun',
				explain: '用户点击注册后执行的函数',
				type: 'function',
				default: ''
			},
			{
				key: '6',
				parameter: 'bottomFunc',
				explain: '用户点击底部按钮后执行的函数',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wlogin 用户登陆',
		subtitle: '用户登陆，账号、密码填写，登陆、注册跳转。',
		useArr: [
			'用于手机端App用户登陆界面。',
			'支持用户输入手机号码、用户名、密码，用于注册用户进行应用的登陆。'
		],
		codename: 'Wlogin',
		codeImage: Images.wlogin,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'centerStyle',
				explain: '中间容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'inputContainerStyle',
				explain: '输入框容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'leftImgStyle',
				explain: '输入框内部左侧图片容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'imgStyle',
				explain: '输入框左侧容器图片样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'imgUrlTop',
				explain: '输入框顶部左侧容器图片地址 (属于网络图片) 设置',
				type: 'string',
				default: ''
			},
			{
				key: '7',
				parameter: 'imgUrlBottom',
				explain: '输入框底部左侧容器图片地址 (属于网络图片) 设置',
				type: 'string',
				default: ''
			},
			{
				key: '8',
				parameter: 'localImgUrlTop',
				explain: '输入框顶部左侧容器图片地址 (属于本地图片) 设置',
				type: 'string',
				default: ''
			},
			{
				key: '9',
				parameter: 'localImgUrlBottom',
				explain: '输入框底部左侧容器图片地址 (属于本地图片) 设置',
				type: 'string',
				default: ''
			},
			{
				key: '10',
				parameter: 'inputStyle',
				explain: '输入框样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '11',
				parameter: 'bgUriStyle',
				explain: '背景图片样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '12',
				parameter: 'bgUrl',
				explain: '背景图片地址（属于网路图片地址）设置',
				type: 'string',
				default: ''
			},
			{
				key: '13',
				parameter: 'localBgUrl',
				explain: '背景图片地址（属于本地图片地址）设置',
				type: 'string',
				default: ''
			},
			{
				key: '14',
				parameter: 'submitStyle',
				explain: '提交按钮样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '15',
				parameter: 'submitText',
				explain: '提交按钮文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '16',
				parameter: 'submitTextStyle',
				explain: '提交按钮文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '17',
				parameter: 'leftContentText',
				explain: '左侧文字显示内容',
				type: 'string | number',
				default: ''
			},
			{
				key: '18',
				parameter: 'rightContentText',
				explain: '右侧文字显示内容',
				type: 'string | number',
				default: ''
			},
			{
				key: '19',
				parameter: 'leftTextStyle',
				explain: '左侧文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '20',
				parameter: 'rightTextStyle',
				explain: '右侧文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '21',
				parameter: 'bottomStyle',
				explain: '底部容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '22',
				parameter: 'bottomText',
				explain: '底部容器文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '23',
				parameter: 'bottomTextStyle',
				explain: '底部容器文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '24',
				parameter: 'logoStyle',
				explain: 'logo样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '25',
				parameter: 'logoUrl',
				explain: 'logo地址设置',
				type: 'string',
				default: ''
			},
			{
				key: '26',
				parameter: 'logoTitleConStyles',
				explain: 'logo标题容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '27',
				parameter: 'logoTitleStyles',
				explain: 'logo标题文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '28',
				parameter: 'logoTitle',
				explain: 'logo标题文字设置',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'userInputText',
				explain: '获取当前用户输入的用户名',
				type: 'function',
				default: ''
			},
			{
				key: '2',
				parameter: 'passInputtext',
				explain: '获取当前用户输入的密码',
				type: 'function',
				default: ''
			},
			{
				key: '3',
				parameter: 'submitFunc',
				explain: '用户点击提交后执行的函数',
				type: 'function',
				default: ''
			},
			{
				key: '4',
				parameter: 'leftFunc',
				explain: '用户点击左侧按钮后执行的函数',
				type: 'function',
				default: ''
			},
			{
				key: '5',
				parameter: 'rightFunc',
				explain: '用户点击右侧按钮后执行的函数',
				type: 'function',
				default: ''
			},
			{
				key: '6',
				parameter: 'bottomFunc',
				explain: '用户点击底部按钮后执行的函数',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wheader 顶部导航',
		subtitle: '顶部导航，支持返回和按钮设置。',
		useArr: [
			'用于手机端App用户详情界面的返回。',
			'支持用户自定义按钮。',
			'支持用户自定义按钮功能。'
		],
		codename: 'Wheader',
		codeImage: Images.wheader,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'leftStyle',
				explain: '左侧容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'rightStyle',
				explain: '右侧容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'textStyle',
				explain: '标题字体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'textContent',
				explain: '标题文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '6',
				parameter: 'leftContent',
				explain: '左侧容器内容设置',
				type: 'function',
				default: ''
			},
			{
				key: '7',
				parameter: 'rightContent',
				explain: '右侧容器内容设置',
				type: 'function',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'leftClick',
				explain: '点击左侧执行的方法',
				type: 'function',
				default: ''
			},
			{
				key: '2',
				parameter: 'rightClick',
				explain: '点击右侧执行的方法',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wtitle 标题导航',
		subtitle: '标题导航。',
		useArr: [
			'用于App手机模块标题的展示。',
			'支持用户自定义按钮样式、显示和隐藏。',
		],
		codename: 'Wtitle',
		codeImage: Images.wtitle,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'leftStyle',
				explain: '左侧容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'rightStyle',
				explain: '右侧容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'textStyle',
				explain: '标题样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'titleContent',
				explain: '标题文字显示设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '6',
				parameter: 'rightTextStyle',
				explain: '右侧文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '7',
				parameter: 'rightTextContent',
				explain: '右侧显示文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '8',
				parameter: 'rightContent',
				explain: '右侧显示图标设置',
				type: 'function',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'clickRight',
				explain: '右侧点击事件设置',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wsearch 顶部搜索',
		subtitle: '顶部搜索。',
		useArr: [
			'用于App手机模块顶部搜索框展示。'
		],
		codename: 'Wsearch',
		codeImage: Images.wsearch,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'inputStyle',
				explain: '输入框样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'logoStyle',
				explain: 'logo图片容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'searchStyle',
				explain: '搜索图片容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'isShowLogo',
				explain: '是否显示logo图片',
				type: 'bool',
				default: ''
			},
			{
				key: '6',
				parameter: 'isShowSearch',
				explain: '是否显示搜索图片',
				type: 'bool',
				default: ''
			},
			{
				key: '7',
				parameter: 'placeholderText',
				explain: '输入框提示文字内容',
				type: 'object',
				default: ''
			},
			{
				key: '8',
				parameter: 'placeholderColor',
				explain: '输入框提示文字内容颜色',
				type: 'string',
				default: ''
			},
			{
				key: '9',
				parameter: 'type',
				explain: '输入框类型，直接输入和点击后跳转两种',
				type: 'number',
				default: ''
			},
			{
				key: '10',
				parameter: 'logoUrl',
				explain: 'logo图片地址',
				type: 'string',
				default: ''
			},
			{
				key: '11',
				parameter: 'searchUrl',
				explain: '图片地址',
				type: 'string',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'contentInputFunc',
				explain: '获取输入的内容',
				type: 'function',
				default: ''
			},
			{
				key: '2',
				parameter: 'searchClick',
				explain: '点击搜索执行的函数',
				type: 'function',
				default: ''
			},
			{
				key: '3',
				parameter: 'typeClick',
				explain: 'type为1的时候点击搜索框执行的跳转函数',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wtab 选项切换',
		subtitle: '选项切换，类似于tab的用法。',
		useArr: [
			'用于App端的分类信息展示。',
			'带过渡滑动动画。'
		],
		codename: 'Wtab',
		codeImage: Images.wtab,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'itemStyle',
				explain: '内部元素样式',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'tabList',
				explain: '标题数组',
				type: 'arr',
				default: ''
			},
			{
				key: '4',
				parameter: 'sliderStyle',
				explain: '底部滑块的样式',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'tabWidth',
				explain: 'tab宽度设置',
				type: 'bool',
				default: ''
			},
			{
				key: '6',
				parameter: 'selectTextStyle',
				explain: '当前tab选中后文字的颜色设置',
				type: 'bool',
				default: ''
			},
			{
				key: '7',
				parameter: 'noSelectTextStyle',
				explain: '当前tab未选中后文字的颜色设置',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'clickTab',
				explain: '点击选项返回的当前点击的第几个',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'WtopTitle 吸顶导航',
		subtitle: '吸顶导航，顶部吸附效果。',
		useArr: [
			'滑动到指定距离，导航吸附顶部。',
			'向上滚动屏幕，蓝色条会吸附在顶部，向下则回到原始位置。'
		],
		codename: 'WtopTitle',
		codeImage: Images.wtoptitle,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'tabHeight',
				explain: '顶部容器的高度',
				type: 'number',
				default: ''
			},
			{
				key: '3',
				parameter: 'titleStyleOne',
				explain: '顶部标题样式设置',
				type: 'arr',
				default: ''
			},
			{
				key: '4',
				parameter: 'titleStyleTwo',
				explain: '顶部悬浮标题样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'topContentHeight',
				explain: '顶部容器高度设置',
				type: 'number',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'tabInner',
				explain: 'tab标签内部显示内容',
				type: 'function',
				default: ''
			},
			{
				key: '2',
				parameter: 'topContent',
				explain: '顶部容器内部显示内容',
				type: 'function',
				default: ''
			},
			{
				key: '3',
				parameter: 'topContent',
				explain: '底部容器内部显示内容',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wcard 资料卡片',
		subtitle: '资料卡片。',
		useArr: [
			'用于用户信息的遍历展示，点击后跳转到指定详情页面。'
		],
		codename: 'Wcard',
		codeImage: Images.wcard,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'type',
				explain: '组件类型选择，传递数字number切换组件的样式',
				type: 'number',
				default: ''
			},
			{
				key: '3',
				parameter: 'userStyle',
				explain: '卡片头像容器样式设置',
				type: 'arr',
				default: ''
			},
			{
				key: '4',
				parameter: 'imgStyle',
				explain: '卡片头像样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'imgUrl',
				explain: '卡片头像图片路径',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'rightStyle',
				explain: '卡片右侧容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '7',
				parameter: 'textStyle',
				explain: '容器字体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '8',
				parameter: 'textTitle',
				explain: '容器需要显示的文字',
				type: 'string | number',
				default: ''
			},
			{
				key: '9',
				parameter: 'trightStyle',
				explain: '容器右侧样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '10',
				parameter: 'textStyleone、textStyletwo、textStylethree',
				explain: '容器内部文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '11',
				parameter: 'textContentone，textContenttwo，textContentthree',
				explain: '容器内部文字设置',
				type: 'string | number',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'clickFunc',
				explain: '点击容器需要执行的函数',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'WimageCard 图片卡片',
		subtitle: '图片卡片。',
		useArr: [
			'用于用户信息的遍历展示，点击后跳转到指定详情页面。'
		],
		codename: 'WimageCard',
		codeImage: Images.wimagecard,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'imgStyle',
				explain: '图片样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'imgUrl',
				explain: '图片地址设置',
				type: 'string',
				default: ''
			},
			{
				key: '4',
				parameter: 'bottomConStyle',
				explain: '底部容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'bottomTitleStyle',
				explain: '底部容器标题文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'titleText',
				explain: '底部容器标题文字设置',
				type: 'string',
				default: ''
			},
			{
				key: '7',
				parameter: 'bottomBriefStyle',
				explain: '底部容器简介文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '8',
				parameter: 'briefText',
				explain: '底部容器简介文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '9',
				parameter: 'bottomLeftConStyle',
				explain: '底部左侧容器设置',
				type: 'object',
				default: ''
			},
			{
				key: '10',
				parameter: 'bottomRightConStyle',
				explain: '底部右侧容器设置',
				type: 'object',
				default: ''
			},
			{
				key: '11',
				parameter: 'moreStyle',
				explain: '更多样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '12',
				parameter: 'moreText',
				explain: '更多文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '13',
				parameter: 'mapConstyle',
				explain: '星星容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '14',
				parameter: 'starStyle',
				explain: '星星样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '15',
				parameter: 'starNum',
				explain: '星星评分设置',
				type: 'number',
				default: ''
			},
			{
				key: '16',
				parameter: 'choiceStarColor',
				explain: '选中星星的颜色',
				type: 'string',
				default: ''
			},
			{
				key: '17',
				parameter: 'noChoiceStarColor',
				explain: '未选中星星的颜色',
				type: 'string',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'moreFun',
				explain: '点击更多后跳转的界面',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wcardone 资料卡片',
		subtitle: '资料卡片。',
		useArr: [
			'用户用户信息的遍历展示，点击后跳转到指定详情页面。'
		],
		codename: 'Wcardone',
		codeImage: Images.wcardone,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '外层容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'innerContentStyle',
				explain: '内层容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'imageStyle',
				explain: '图片样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'imageUrl',
				explain: '图片路径',
				type: 'string',
				default: ''
			},
			{
				key: '5',
				parameter: 'titleContentStyle',
				explain: '标题容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'titleTextStyle',
				explain: '标题文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '7',
				parameter: 'titleText',
				explain: '标题文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '8',
				parameter: 'subtitleText',
				explain: '副标题文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '9',
				parameter: 'subtitleTextStyle',
				explain: '副标题文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '10',
				parameter: 'bottomContentStyle',
				explain: '底部容器设置',
				type: 'object',
				default: ''
			},
			{
				key: '11',
				parameter: 'bLeftConStyle',
				explain: '底部左侧容器设置',
				type: 'object',
				default: ''
			},
			{
				key: '12',
				parameter: 'bRightConStyle',
				explain: '底部右侧容器设置',
				type: 'object',
				default: ''
			},
			{
				key: '13',
				parameter: 'bottomIconStyle',
				explain: '底部左侧图标样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '14',
				parameter: 'bottomRIconStyle',
				explain: '底部右侧图标样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '15',
				parameter: 'seeImg',
				explain: '查看图标设置',
				type: 'string',
				default: ''
			},
			{
				key: '16',
				parameter: 'seeNumber',
				explain: '查看图标右侧显示文字设置设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '17',
				parameter: 'commentImg',
				explain: '评论图标设置',
				type: 'string',
				default: ''
			},
			{
				key: '18',
				parameter: 'commentRImg',
				explain: '右侧评论图标设置',
				type: 'string',
				default: ''
			},
			{
				key: '19',
				parameter: 'commentNumber',
				explain: '评论图标右侧显示文字设置设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '20',
				parameter: 'bottomTextStyle',
				explain: '左侧容器显示字体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '21',
				parameter: 'heartImg',
				explain: '左侧容器显示图标志样式设置',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'clickFunc',
				explain: '点击容器需要执行的函数',
				type: 'function',
				default: ''
			},
			{
				key: '2',
				parameter: 'commentClickFun',
				explain: '点击右下角评论图标需要执行的函数',
				type: 'function',
				default: ''
			},
			{
				key: '3',
				parameter: 'heartClickFun',
				explain: '点击右下角喜爱图标需要执行的函数',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Warticle 文章列表',
		subtitle: '文章列表。',
		useArr: [
			'用户用户信息的遍历展示，点击后跳转到指定详情页面。'
		],
		codename: 'Warticle',
		codeImage: Images.warticle,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '层容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'topContainerStyle',
				explain: '顶部容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'topLeftConStyle',
				explain: '顶部左侧容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'topRightConStyle',
				explain: '顶部右侧容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'topImgStyle',
				explain: '顶部头像样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'topImgUrl',
				explain: '顶部头像地址设置',
				type: 'string',
				default: ''
			},
			{
				key: '7',
				parameter: 'userConStyle',
				explain: '顶部用户名容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '8',
				parameter: 'userTextStyle',
				explain: '顶部用户名文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '9',
				parameter: 'userText',
				explain: '顶部用户名文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '10',
				parameter: 'tagText',
				explain: '顶部用户名文字右侧标签文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '11',
				parameter: 'tagTextConStyle',
				explain: '顶部用户名文字右侧标签容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '12',
				parameter: 'tagTextStyle',
				explain: '顶部用户名文字右侧标签文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '13',
				parameter: 'briefText',
				explain: '用户个人简介文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '14',
				parameter: 'briefTextStyle',
				explain: '用户个人简介文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '15',
				parameter: 'titleTextStyle',
				explain: '文章标题文字样式设置',
				type: 'string',
				default: ''
			},
			{
				key: '16',
				parameter: 'titleText',
				explain: '文章标题文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '17',
				parameter: 'contentText',
				explain: '文章内容文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '18',
				parameter: 'contentTextStyle',
				explain: '文章内容文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '19',
				parameter: 'imgConStyle',
				explain: '图片容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '20',
				parameter: 'imgItemStyle',
				explain: '单个图片单元容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '21',
				parameter: 'contentImgStyle',
				explain: '图片样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '22',
				parameter: 'imgDate',
				explain: '图片数组数据设置',
				type: 'arr',
				default: ''
			},
			{
				key: '23',
				parameter: 'bottomConItemStyle',
				explain: '底部容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '24',
				parameter: 'bottomImgStyle',
				explain: '底部图片样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '25',
				parameter: 'bottomTextStyle',
				explain: '底部文字样式设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '26',
				parameter: 'bottomTextOne',
				explain: '底部文字一设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '27',
				parameter: 'bottomTextTwo',
				explain: '底部文字二设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '28',
				parameter: 'bottomTextThree',
				explain: '底部文字三设置',
				type: 'string | number',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'clickFunc',
				explain: '点击文章执行的操作',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wcomment 评论列表',
		subtitle: '评论列表。',
		useArr: [
			'用户用户信息的遍历展示，点击后跳转到指定详情页面。'
		],
		codename: 'Wcomment',
		codeImage: Images.wcomment,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '层容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'topConStyle',
				explain: '顶部容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'topLeftUserConStyle',
				explain: '顶部左侧容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'imgStyle',
				explain: '用户头像样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'userImgUrl',
				explain: '用户头像地址设置',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'topRightConStyle',
				explain: '顶部右侧容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '7',
				parameter: 'userText',
				explain: '用户名称设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '8',
				parameter: 'userTextStyle',
				explain: '用户名称文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '9',
				parameter: 'timeText',
				explain: '用户名称下方时间文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '10',
				parameter: 'timeTextStyle',
				explain: '用户名称下方时间文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '11',
				parameter: 'commentConStyle',
				explain: '评论框总体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '12',
				parameter: 'commentConLeftStyle',
				explain: '评论框内部左侧样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '13',
				parameter: 'commentConRightStyle',
				explain: '评论框内部右侧样式设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '14',
				parameter: 'commentTextStyle',
				explain: '评论框内部文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '15',
				parameter: 'commentText',
				explain: '评论框内部文字设置',
				type: 'string',
				default: ''
			},
			{
				key: '16',
				parameter: 'starNum',
				explain: '星星的总个数',
				type: 'array',
				default: ''
			},
			{
				key: '17',
				parameter: 'starStyle',
				explain: '底部星星的样式',
				type: 'string | number',
				default: ''
			},
			{
				key: '18',
				parameter: 'scoreNum',
				explain: '当前的评分星级',
				type: 'number',
				default: ''
			},
			{
				key: '19',
				parameter: 'choiceColor',
				explain: '星星选中时候的颜色',
				type: 'string',
				default: ''
			},
			{
				key: '20',
				parameter: 'noChoiceColor',
				explain: '星星未选中时候的颜色',
				type: 'string',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: '-',
				explain: '-',
				type: '-',
				default: ''
			}
		]
	},
	{
		name: 'Wloading 加载提示',
		subtitle: '加载提示。',
		useArr: [
			'用于界面数据未能及时加载时候的loading页面。',
			'支持自定义图标样式.'
		],
		codename: 'Wloading',
		codeImage: Images.wloading,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'imgStyle',
				explain: '旋转图片样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'loadingText',
				explain: '旋转图片下方文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '4',
				parameter: 'loadingTextStyle',
				explain: '旋转图片下方文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'rotateImg',
				explain: '旋转图片设置',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'type',
				explain: '切换旋转图片样式（4种样式)',
				type: 'number',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: '-',
				explain: '-',
				type: '-',
				default: ''
			}
		]
	},
	{
		name: 'Wtips 弹框提示',
		subtitle: '弹框提示。',
		useArr: [
			'用于App界面的轻提示信息展示。'
		],
		codename: 'Wtips',
		codeImage: Images.wtips,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'tipsText',
				explain: '提示文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '3',
				parameter: 'tipsTextStyle',
				explain: '提示文字样式设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '4',
				parameter: 'textConStyle',
				explain: '提示文字容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'tipPosition',
				explain: '提示文字容器显示的位置，默认为中间,可以设置为top，同时设置top字段控制容器距离顶部的高度',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'top',
				explain: '控制弹出框距离顶部的高度',
				type: 'number',
				default: ''
			},
			{
				key: '7',
				parameter: 'time',
				explain: '提示文字显示时间设置，默认1.5s',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'Wtips.show(message,time,function)',
				explain: 'message为提示消息，time为提示时间，function为刷新函数',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'WtimeSelect 时间选择',
		subtitle: '时间选择。',
		useArr: [
			'横向滑动分段选择时间组件。'
		],
		codename: 'WtimeSelect',
		codeImage: Images.wtimeselect,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'pointerStyle',
				explain: '顶部指针样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'itemStyle',
				explain: '滚动单元样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'timeTextStyle',
				explain: '时间文字显示样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'textChoiceColor',
				explain: '当前选中时间文字颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'textNoChoiceColor',
				explain: '当前没有选中时间文字颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '7',
				parameter: 'bottomPointerStyle',
				explain: '底部刻度样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '8',
				parameter: 'pointerChoiceColor',
				explain: '底部刻度选中颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '9',
				parameter: 'pointerNoChoiceColor',
				explain: '底部刻度未选中颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '10',
				parameter: 'timeData',
				explain: '需要传入的显示时间的数组',
				type: 'array',
				default: ''
			},
			{
				key: '11',
				parameter: 'equipartition',
				explain: '当前所见时间轴的份数（默认5)',
				type: 'number',
				default: ''
			},
			{
				key: '12',
				parameter: 'timeData',
				explain: '需要传入的显示时间的数组',
				type: 'array',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'currentTimeFun',
				explain: '滚动的时候返回的当前时间',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wdate 日期选择',
		subtitle: '日期选择。',
		useArr: [
			'点击按钮切换当前显示时间、月份等信息。'
		],
		codename: 'Wdate',
		codeImage: Images.wdate,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'noChoiceDateStyle',
				explain: '没有选中日期的文本样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'choiceDateStyle',
				explain: '选中日期的文本样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'topMonthStyle',
				explain: '顶部显示文本样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'bottomWeekStyle',
				explain: '底部显示的文本样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'imgStyle',
				explain: '左右箭头样式调整（大小，间距）',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'timeFunc',
				explain: '点击左右按钮返回当前的日期等数据',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wdatepicker 弹框日历',
		subtitle: '弹框日历。',
		useArr: [
			'用于App的datepicker展示，选择时间或者省份。',
			'可以自定义内容。'
		],
		codename: 'Wdatepicker',
		codeImage: Images.wdatepicker,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'topContainerStyle',
				explain: '标题容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'trueTextStyle',
				explain: '确定文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'falseTextStyle',
				explain: '取消文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'dataContainerStyle',
				explain: '中间日期容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'trueText',
				explain: '确定文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '7',
				parameter: 'falseText',
				explain: '取消文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '8',
				parameter: 'year',
				explain: '传入的年数据',
				type: 'array',
				default: ''
			},
			{
				key: '9',
				parameter: 'month',
				explain: '传入的月数据',
				type: 'array',
				default: ''
			},
			{
				key: '10',
				parameter: 'pickerStyleOne',
				explain: '年选择容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '11',
				parameter: 'pickerStyleTwo',
				explain: '月选择容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '12',
				parameter: 'pickerStyleThree',
				explain: '日选择容器样式设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '13',
				parameter: 'pickerTitle',
				explain: '顶部标题设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '14',
				parameter: 'pickerTitleStyle',
				explain: '顶部标题样式设置',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'isShow',
				explain: '控制picker的显示和隐藏',
				type: 'bool',
				default: ''
			},
			{
				key: '2',
				parameter: 'closeModal',
				explain: '关闭modal时候的回调函数',
				type: 'function',
				default: ''
			},
			{
				key: '3',
				parameter: 'trueButton',
				explain: '点击确定按钮后的回调函数',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wcategory 分类选择',
		subtitle: '分类选择。',
		useArr: [
			'用于分类信息选择，商城尺寸、颜色等。'
		],
		codename: 'Wcategory',
		codeImage: Images.wcategory,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'titleTextStyle',
				explain: '顶部标题文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'titleText',
				explain: '顶部标题文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '4',
				parameter: 'listConStyle',
				explain: '列表容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'listItemConStyle',
				explain: '列表容器内部单元样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'tagData',
				explain: '需要遍历的列表数据',
				type: 'array',
				default: ''
			},
			{
				key: '7',
				parameter: 'itemTextStyle',
				explain: '列表单元容器内部文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '8',
				parameter: 'bgChoiceColor',
				explain: '列表单元容器内部背景色选中的颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '9',
				parameter: 'bgNoChoiceColor',
				explain: '列表单元容器内部背景色未选中的颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '10',
				parameter: 'textChoiceColor',
				explain: '列表单元容器内部文字选中的颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '11',
				parameter: 'textNoChoiceColor',
				explain: '列表单元容器内部文字未选中的颜色设置',
				type: 'string',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'clickFun',
				explain: '点击标签时候的回调函数，返回当前点击的一些信息',
				type: 'bool',
				default: ''
			}
		]
	},
	{
		name: 'Wswitch 滑动开关',
		subtitle: '滑动开关。',
		useArr: [
			'开启、关闭的UI图形展示。',
			'可以自定义内容、颜色、大小。'
		],
		codename: 'Wswitch',
		codeImage: Images.wswitch,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'sliderRight',
				explain: '向右侧滚动的距离设置，设置开关大小的时候要用到',
				type: 'number',
				default: ''
			},
			{
				key: '3',
				parameter: 'bgChoiceColor',
				explain: '开关打开时候的背景颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '4',
				parameter: 'bgNoChoiceColor',
				explain: '开关关闭时候的背景颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '5',
				parameter: 'sliderItemColor',
				explain: '中间圆点的颜色设置',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'itemConStyle',
				explain: '中间圆点的样式设置',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'switchFun',
				explain: '返回当前开关的状态',
				type: 'bool',
				default: ''
			}
		]
	},
	{
		name: 'Whelp 帮助中心',
		subtitle: '帮助中心。',
		useArr: [
			'用于分类信息的展示。'
		],
		codename: 'Whelp',
		codeImage: Images.whelp,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'titleStyle',
				explain: '标题容器的样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'titleTextStyle',
				explain: '标题容器文字的样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'titleText',
				explain: '标题容器文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '5',
				parameter: 'contentStyle',
				explain: '内容容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'mapData',
				explain: '内容数组设置',
				type: 'array',
				default: ''
			},
			{
				key: '7',
				parameter: 'mapTextStyle',
				explain: '内容文字样式设置',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: '-',
				explain: '-',
				type: '-',
				default: ''
			}
		]
	},
	{
		name: 'WverificationCode 人机验证',
		subtitle: '人机验证。',
		useArr: [
			'用于输入验证码登陆应用。'
		],
		codename: 'WverificationCode',
		codeImage: Images.wverificationcode,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'roundStyle',
				explain: '散布圆点样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'roundNum',
				explain: '散布圆点个数设置',
				type: 'number',
				default: ''
			},
			{
				key: '4',
				parameter: 'numCodeStyle',
				explain: '验证码数字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'lineNum',
				explain: '散布线点个数设置',
				type: 'number',
				default: ''
			},
			{
				key: '6',
				parameter: 'isRefresh',
				explain: '是否刷新当前验证码',
				type: 'bool',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'currentCode',
				explain: '返回当前显示的验证码',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wprogress 进度展示',
		subtitle: '进度展示',
		useArr: [
			'用于展示当前工作进度。'
		],
		codename: 'Wprogress',
		codeImage: Images.wprogress,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'barConStyle',
				explain: '进度条容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'barStyle',
				explain: '进度条样式',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'textProgressStyle',
				explain: '文字展示样式',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'currentProgress',
				explain: '当前进度数值',
				type: 'number',
				default: ''
			},
			{
				key: '6',
				parameter: 'totalProgress',
				explain: '总进度数值',
				type: 'number',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: '_',
				explain: '_',
				type: '_',
				default: ''
			}
		]
	},
	{
		name: 'Wquantity 数量选择',
		subtitle: '数量选择。',
		useArr: [
			'用于商品数量的增加和减少。'
		],
		codename: 'Wquantity',
		codeImage: Images.wquantity,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'leftConStyle',
				explain: '左侧按钮容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'rightConStyle',
				explain: '右侧按钮容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'leftBtnTextStyle',
				explain: '右侧按钮容器文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'rightBtnTextStyle',
				explain: '右侧按钮容器文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'leftBtnText',
				explain: '左侧按钮文字内容设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '7',
				parameter: 'rightBtnText',
				explain: '右侧按钮文字内容设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '8',
				parameter: 'centerTextStyle',
				explain: '中间文字样式设置（中间为一个TextInput标签，可以设置背景色）',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'currentValue',
				explain: '点击加减和输入数量后的回调函数，会返回当前的数值',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wspinner 下拉列表',
		subtitle: '下拉列表。',
		useArr: [
			'用于商品详细信息的展示和隐藏。'
		],
		codename: 'Wspinner',
		codeImage: Images.wspinner,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'wrapCon',
				explain: '最外层容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'openCon',
				explain: '列表展开时候的右侧容器显示的内容',
				type: 'function',
				default: ''
			},
			{
				key: '4',
				parameter: 'closeCon',
				explain: '列表收起时候的右侧容器显示的内容',
				type: 'function',
				default: ''
			},
			{
				key: '5',
				parameter: 'titleStyle',
				explain: '列表标题文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '6',
				parameter: 'titleText',
				explain: '列表标题文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '7',
				parameter: 'listData',
				explain: '循环展示的单元',
				type: 'array',
				default: ''
			},
			{
				key: '8',
				parameter: 'listItemStyle',
				explain: '展示单元的样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '9',
				parameter: 'itemTitleTextStyle',
				explain: '展示单元标题字体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '10',
				parameter: 'itemConTextStyle',
				explain: '展示单元文字内容样式设置',
				type: 'object',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: '-',
				explain: '-',
				type: '-',
				default: ''
			}
		]
	},
	{
		name: 'Wmessage 聊天消息',
		subtitle: '聊天消息。',
		useArr: [
			'用于展示对话内容。'
		],
		codename: 'Wmessage',
		codeImage: Images.wmessage,
		apiArr: [
			{
				key: '1',
				parameter: 'messageConStyle',
				explain: '消息容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'wrapCon',
				explain: '最外层容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'bottomConStyle',
				explain: '底部容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'sendConStyle',
				explain: '发送按钮样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '5',
				parameter: 'sendImgUrl',
				explain: '发送按钮图片地址设置',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'sendImgStyle',
				explain: '发送按钮图片样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '7',
				parameter: 'inputStyle',
				explain: '输入框样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '8',
				parameter: 'avatarStyle',
				explain: '消息头像样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '9',
				parameter: 'leftItemConStyle',
				explain: '左侧消息容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '10',
				parameter: 'leftPopupStyle',
				explain: '左侧消息展示容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '11',
				parameter: 'leftPopupInnerStyle',
				explain: '左侧消息展示内部容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '12',
				parameter: 'lContentTextStyle',
				explain: '左侧消息展示内部文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '13',
				parameter: 'rContentTextStyle',
				explain: '右侧消息展示内部文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '14',
				parameter: 'leftID',
				explain: '判断当前用户发送的信息展示的位置（左侧还是右侧）',
				type: 'string | number',
				default: ''
			},
			{
				key: '15',
				parameter: 'rightPopupStyle',
				explain: '右侧消息展示容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '16',
				parameter: 'rightPopupInnerStyle',
				explain: '右侧消息展示内部容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '17',
				parameter: 'rightItemConStyle',
				explain: '右侧消息容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '18',
				parameter: 'keyboardHeight',
				explain: '设置input抬起高度，防止遮挡键盘',
				type: 'number',
				default: ''
			},
			{
				key: '19',
				parameter: 'avatar',
				explain: '当前发送信息的用户头像',
				type: 'string',
				default: ''
			},
			{
				key: '20',
				parameter: 'id',
				explain: '当前发送信息的用户id',
				type: 'string | number',
				default: ''
			},
			{
				key: '18',
				parameter: 'listTalkData',
				explain: '初始化信息展示数组',
				type: 'array',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'talkArrayBack',
				explain: '每次发送信息后的回调函数，返回当前所有发送信息的内容数组',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wcode 验证计时',
		subtitle: '验证计时。',
		useArr: [
			'用于发送短信后的倒计时操作。'
		],
		codename: 'Wcode',
		codeImage: Images.wcode,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'timeTextStyle',
				explain: '时间显示文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'tipsTextStyle',
				explain: '提示文字样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'tipText',
				explain: '提示文字设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '5',
				parameter: 'unitText',
				explain: '时间单位（s）',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'srcondNum',
				explain: '倒计时秒数设置，默认60s',
				type: 'number',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'clickFunc',
				explain: '每次点击获取验证码后需要执行的函数',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: 'Wpsbox 支付密码',
		subtitle: '支付密码。',
		useArr: [
			'用于钱包的密码输入。'
		],
		codename: 'Wpsbox',
		codeImage: Images.wpsbox,
		apiArr: [
			{
				key: '1',
				parameter: 'containerStyle',
				explain: '容器整体样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '2',
				parameter: 'inputBoxStyle',
				explain: '输入框外侧容器样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '3',
				parameter: 'inputStyle',
				explain: '输入框样式设置',
				type: 'object',
				default: ''
			},
			{
				key: '4',
				parameter: 'textStyle',
				explain: '输入框内文字样式设置',
				type: 'string | number',
				default: ''
			},
			{
				key: '5',
				parameter: 'choiceBorderColor',
				explain: '输入框选中时边框颜色样式设置',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'noChoiceBorderColor',
				explain: '输入框未选中时边框颜色样式设置',
				type: 'string',
				default: ''
			},
			{
				key: '6',
				parameter: 'inputBoxCount',
				explain: '输入框的显示个数（默认为4个）',
				type: 'number',
				default: ''
			}
		],
		methodArr: [
			{
				key: '1',
				parameter: 'inputFunc',
				explain: '输入完验证码后返回的输入值',
				type: 'function',
				default: ''
			}
		]
	},
	{
		name: '关于我们',
		subtitle: 'QQ:51903  QQ群:1552772',
		useArr: [
			''
		],
		codename: '',
		codeImage: '',
		apiArr: [],
		methodArr: []
	},
	// 方法
	{
		maintitle: '常用函数',
		methodArr: [
			{
				name: 'parseRegister',
				parameterArr: [
					'/**',
					'* 用户注册',
					'* @param { 当前的Parse对象new Parse.User() }  a',
					"* @param 需要设置的字段类型为此 { username:'wyx',password:'123456',phone:'10086' } b",
					"* @param { 成功或者失败后的回调函数 } c",
					"**/"
				],
				funcArr: [
					'function parseRegister(a,b,c){',
					'let user = a',
					'for(let i in b){',
					'user.set(i,b[i])',
					'}',
					'user.signUp().then(req=>{',
					'c(req)',
					' }).catch(err=>{',
					'c(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 2, 4, 2, 2, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parseLogin',
				parameterArr: [
					'/**',
					'* 用户登陆',
					'* @param {当前的Parse对象} a',
					"* @param {当前需要登陆的用户名} b",
					"* @param {当前需要登陆的用户密码} c",
					"* @param {登陆成功或者失败后的回掉函数} d",
					"* @param {是否对数据进行序列化后在输出，默认为false} e",
					"**/"
				],
				funcArr: [
					'function parseLogin(a,b,c,d,e=false){',
					'a.User.logIn(b,c).then(req=>{',
					'if(e){',
					'let obj = JSON.parse(JSON.stringify(req))',
					'obj.id = e.id',
					'obj.className = e.className',
					'}',
					'd(e?obj:req)',
					'}).catch(err=>{',
					'd(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 4, 6, 6, 6, 4, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parseDataFormate',
				parameterArr: [
					'/**',
					'* 格式化获取的数据',
					'* @param {用户需要格式化的数据，可以是对象或者是数组对象的形式} a',
					"**/"
				],
				funcArr: [
					'function parseDataFormate(a){',
					'if(a instanceof Array){',
					'let backArr = []',
					'for(let i=0; i<a.length; i++){',
					'let obj = {}',
					"obj.className = a[i]['className']",
					"obj.id = a[i]['id']",
					"obj._objCount = a[i]['_objCount']",
					'for(let j in a[i].attributes){',
					'obj[j] = a[i].attributes[j]',
					'}',
					'backArr.push(obj)',
					'}',
					'return backArr',
					'}else{',
					'let obj = {}',
					"obj.className = a['className']",
					"obj.id = a['id']",
					"obj._objCount = a['_objCount']",
					'for(let j in a.attributes){',
					'obj[j] = a.attributes[j]',
					'}',
					'return obj',
					'}',
					'}'
				],
				textindentArr: [
					0, 2, 4, 4, 6, 6, 6, 6, 6, 8, 6, 6, 4, 4, 2, 4, 4, 4, 4, 4, 6, 4, 4, 2, 0
				]
			},
			{
				name: 'parseGetData',
				parameterArr: [
					'/**',
					'* 用户查询当前class类的数据',
					'* @param {传入的Parse对象} a',
					'* @param {需要查询的class类名} b',
					'* @param {查询成功后的回调函数} c',
					'* @param {查询的字段中是否包含Pointer字段，包含的话查询字段名称} d',
					"**/"
				],
				funcArr: [
					"function parseGetData(a,b,c,d=''){",
					'let queryData = a.Object.extend(b)',
					'let queryDatas = new a.Query(queryData)',
					"if(d===''){}else{",
					'queryDatas.include(d)',
					'}',
					'queryDatas.find().then(req=>{',
					'c(req)',
					'}).catch(err=>{',
					'c(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 2, 2, 4, 2, 2, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parseAddData',
				parameterArr: [
					'/**',
					'* 用户增加当前class的数据',
					'* @param {传入的Parse对象} a',
					'* @param {当前想要增加数据的class类名} b',
					'* @param {当前需要设置字段和改字段需要设置的值} c',
					'* @param {设置成功或失败后的回调函数} d',
					'* @param {是否设置有关Pointer字段的属性，默认为false} e',
					'* @param {添加改Pointer对象的数组[[f,g,h],[f,g,h]]} f',
					'* 数组参数说明 * @param {关联字段的Class类名字} f',
					'* @param {需要关联对象的ID} g',
					'* @param {该Pointer字段在增加数据class类名中的名字} h',
					"**/"
				],
				funcArr: [
					'function parseAddData(a,b,c,d,e=false,f){',
					'const addDatas = a.Object.extend(b)',
					'const addData = new addDatas()',
					'for(let i in c){',
					'addData.set(i,c[i])',
					'}',
					'if(e){',
					'for(let i=0;i<f.length;i++){',
					'let T = Parse.Object.extend(f[i][0])',
					'let t = T.createWithoutData(f[i][1])',
					'addData.set(f[i][2],t)',
					'}',
					'}',
					'addData.save().then(req=>{',
					'd(req)',
					'}).catch(err=>{',
					'd(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 2, 2, 4, 2, 2, 4, 6, 6, 6, 4, 2, 2, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parseRemoveData',
				parameterArr: [
					'/**',
					'* 从指定的class类里面删除一个对象',
					'* @param {需要删除的用户数据对象，即class类里面的对象} a',
					'* @param {删除成功或者失败后的回调函数} b',
					"**/"
				],
				funcArr: [
					'function parseRemoveData(a,b){',
					'a.destroy().then(req=>{',
					'b(req)',
					'}).catch(err=>{',
					'b(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parseSearch',
				parameterArr: [
					'/**',
					'* 用户搜索功能',
					'* @param {需要传递的Parse对象} a',
					'* @param {需要在那个class类下进行搜索填写当前的class类名} b',
					'* @param {输入查询的字段的值 Object} c',
					'* @param {查询完成后的返回结果} d',
					'* @param {是否开启模糊匹配 boolean 默认开启} e',
					"**/"
				],
				funcArr: [
					'function parseSearch(a,b,c,d,e=true){',
					'let searchList = a.Object.extend(b)',
					'let list = new a.Query(searchList)',
					'if(e){',
					'for(let i in c){',
					'list.equalTo(i,{"$regex":c[i]})',
					'}',
					'}else{',
					'for(let i in c){',
					'list.equalTo(i,{"$regex":c[i]})',
					'list.equalTo(i,c[i])',
					'}',
					'}',
					'list.find().then(res=>{',
					'd(res)',
					'}).catch(err=>{',
					'd(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 2, 2, 4, 6, 4, 2, 4, 6, 6, 4, 2, 2, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parseGetRelationData',
				parameterArr: [
					'/**',
					'* Relation 形式的数据查询',
					'* @param {需要查询带有relation格式数据的字段} a',
					'* @param {relation格式字段的名称} b',
					'* @param {查询成功或者失败的回调函数} c',
					'* @param {增加条件查询} d',
					"**/"
				],
				funcArr: [
					"function parseGetRelationData(a,b,c,d=''){",
					'let relationData = a.relation(b)',
					"if(d===''){}else{",
					'for(let i in d){',
					'relationData.query().equalTo(i,d[i])',
					'}',
					'}',
					'relationData.query().find().then(req=>{',
					'c(req)',
					'}).catch(err=>{',
					'c(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 2, 4, 6, 4, 2, 2, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parseFileSave',
				parameterArr: [
					'/**',
					'* 存储图片',
					'* @param {需要传入的Parse对象} a',
					'* @param {存储图片的字段名称 String} b',
					'* @param {需要传入的base64图片} c',
					'* @param {图片存储成功后的回调函数} d',
					"**/"
				],
				funcArr: [
					'function parseFileSave(a,b,c,d){',
					'let file = new a.File(b, { base64: c })',
					'file.save().then(req=>{',
					'd(req)',
					'}).catch(err=>{',
					'd(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 2, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parseRelationAdd',
				parameterArr: [
					'/**',
					'* Relation格式的数据的增加',
					'* @param {带有relation数据结构的对象} a',
					'* @param {带有relation数据结构的对象的字段名称} b',
					'* @param {需要添加的对象，可以是单个对象或者是数组对象} c',
					'* @param {添加成功或失败后的回调函数} d',
					"**/"
				],
				funcArr: [
					'function parseRelationAdd(a,b,c,d){',
					'let relation = a.relation(b)',
					'relation.add(c)',
					'a.save().then(req=>{',
					'd(req)',
					'}).catch(err=>{',
					'd(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 2, 2, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parseRelationRemove',
				parameterArr: [
					'/**',
					'* Relation格式的数据的删除',
					'* @param {带有relation数据结构的对象} a',
					'* @param {带有relation数据结构的对象的字段名称} b',
					'* @param {需要删除的对象，可以是单个对象或者是数组对象} c',
					'* @param {删除成功或失败后的回调函数} d',
					"**/"
				],
				funcArr: [
					'function parseRelationRemove(a,b,c,d){',
					'let relation = a.relation(b)',
					'relation.remove(c)',
					'a.save().then(req=>{',
					'd(req)',
					'}).catch(err=>{',
					'd(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 2, 2, 4, 2, 4, 2, 0
				]
			},
			{
				name: 'parsePagingQuery',
				parameterArr: [
					'/**',
					'* Parse分页效果查询，用来做分页',
					'* @param {需要传入的Parse对象} a',
					'* @param {需要查询的class类名} b',
					'* @param {跳过的条数} c',
					'* @param {限制输出的条数} d',
					'* @param {查询成功或者失败的回调函数} e',
					'* @param {指定返回指定的字段，数组格式，里面包含想要查询的字段名称} f',
					"**/"
				],
				funcArr: [
					"function parsePagingQuery(a,b,c,d,e,f=''){",
					'let DataList = a.Object.extend(b)',
					'let queryData = new a.Query(DataList)',
					"if(f===''){}else{",
					'for(let i=0;i<f.length;i++){',
					'queryData.select(f[i])',
					'}',
					'}',
					'queryData.skip(c)',
					'queryData.limit(d)',
					'queryData.find().then(req=>{',
					'e(req)',
					'}).catch(err=>{',
					'e(err)',
					'})',
					'}'
				],
				textindentArr: [
					0, 2, 2, 2, 4, 6, 4, 2, 2, 2, 2, 4, 2, 4, 2, 0
				]
			}
		]
	},
	{
		maintitle: '屏幕适配',
		methodArr: [
			{
				name: 'scaleSizeH scaleSizeW',
				parameterArr: [
					'/**',
					'* scaleSizeH() 高度缩放',
					'* scaleSizeW() 宽度缩放',
					"**/"
				],
				funcArr: [
					'用于适配手机的宽度和高度',
					'这里只使用一个尺寸即可，不用高度和宽度同时缩放'
				],
				textindentArr: [
					0, 0
				]
			},
			{
				name: 'setSpText',
				parameterArr: [
					'/**',
					'* setSpText() 字体大小',
					"**/"
				],
				funcArr: [
					'用于适配手机的字体大小'
				],
				textindentArr: [
					0
				]
			},
			{
				name: 'getStatusBarHeight',
				parameterArr: [
					'/**',
					'* getStatusBarHeight()',
					"**/"
				],
				funcArr: [
					'获取当前状态栏的高度'
				],
				textindentArr: [
					0
				]
			},
			{
				name: 'isIphoneX',
				parameterArr: [
					'/**',
					'* isIphoneX()',
					"**/"
				],
				funcArr: [
					'判断当前手机是否为iPhoneX'
				],
				textindentArr: [
					0
				]
			},
			{
				name: 'fontScale',
				parameterArr: [
					'/**',
					'* fontScale()',
					"**/"
				],
				funcArr: [
					'获取当前手机屏幕的dp'
				],
				textindentArr: [
					0
				]
			},
			{
				name: 'width / height',
				parameterArr: [
					'/**',
					'* width / height',
					"**/"
				],
				funcArr: [
					'获取当前手机屏幕的宽度和高度'
				],
				textindentArr: [
					0
				]
			}
		]
	},
	{
		maintitle: '组件安装',
		methodArr: [
			{
				name: 'react-navigation',
				parameterArr: [
					'1. react-navigation',
					'2. react-navigation-tabs 用于App底部Tab导航',
					'3. react-navigation-stack 用于App之间页面的跳转',
				],
				funcArr: [
					'注意路由的跳转配置，详细信息参考App内配置'
				],
				textindentArr: [
					0, 0
				]
			},
			{
				name: 'react-native-vector-icons',
				parameterArr: [
					'1. npm install react-native-vector-icons',
					'2. cd ios && pod install'
				],
				funcArr: [
					'info.plist 添加以下代码',
					'<key>UIAppFonts</key>',
					'<array>',
					'<string>AntDesign.ttf</string>',
					'<string>Entypo.ttf</string>',
					'<string>EvilIcons.ttf</string>',
					'<string>Feather.ttf</string>',
					'<string>FontAwesome.ttf</string>',
					'<string>FontAwesome5_Brands.ttf</string>',
					'<string>FontAwesome5_Regular.ttf</string>',
					'<string>FontAwesome5_Solid.ttf</string>',
					'<string>Foundation.ttf</string>',
					'<string>Ionicons.ttf</string>',
					'<string>MaterialIcons.ttf</string>',
					'<string>MaterialCommunityIcons.ttf</string>',
					'<string>Octicons.ttf</string>',
					'<string>SimpleLineIcons.ttf</string>',
					'<string>Zocial.ttf</string>',
					'</array>',
					'添加位置',
					'</dict> 上方加入即可'
				],
				textindentArr: [
					0, 2, 2, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 2, 0, 0
				]
			},
			{
				name: 'mobx mobx-react',
				parameterArr: [
					'1. npm install mobx mobx-react'
				],
				funcArr: [
					'新版本rn使用babel@7.0+,所以不能再使用原先的babel-plugin-transform-decorators-legacy(babel@6以下使用)',
					'安装 @babel/plugin-proposal-decorators',
					'配置 .babelrc 文件如下所示',
					'{',
					'"presets": [',
					'"module:metro-react-native-babel-preset"',
					'],',
					'"plugins": [',
					'[',
					'"@babel/plugin-proposal-decorators",',
					'{',
					'"legacy": true',
					'}',
					'}',
					']',
					']',
					'}',
					'App.js根文件下注入store，具体参考App'
				],
				textindentArr: [
					0, 0, 0, 2, 4, 6, 4, 4, 6, 8, 8, 10, 8, 6, 4, 2, 0
				]
			},
		]
	},
	{
		maintitle: '组件使用',
		methodArr: [
			{
				name: 'Three.Js',
				parameterArr: [
					'1. ThreeJs',
				],
				funcArr: [
					'直接下载 three.min.js 文件 https://github.com/mrdoob/three.js',
					'TrackballControls.js 鼠标移动相机跟随移动的js组件，封装好的，可以直接调用',
					'<!-- 引入three.js -->',
					'<script src="./three.min.js"></script>',
					'<script src="./TrackballControls.js"></script>',
					'# 浏览器支持情况',
					'Google Chrome 9+、Firefox 4+、Opera 15+、Safari 5.1+、Internet Explorer 11 和 Microsoft Edge',
					'参考链接: https://caniuse.com/#feat=webgl',
					'现代浏览器都支持 WebGL，这样我们就不必使用 Flash、Java 等插件就能在浏览器中创建三维图形',
					'虽然 WebGL 提供的接口非常丰富和强大，但使用起来过于繁琐和复杂。',
					'Three.js 的出现完美地解决了这个矛盾。它提供一个很简单的关于 WebGL 特性的 JavaScript API，使得用户不需要详细地学习 WebGL，就能轻松创作出好看的三维图形样例。'
				],
				textindentArr: [
					0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
				]
			},
			{
				name: 'Echarts在React中的安装和使用方法',
				parameterArr: [
					'1. Echarts',
				],
				funcArr: [
					'# npm install --save echarts',
					'使用方法',
					"import React, { Component } from 'react'",
					"import echarts from 'echarts'",
					'export class EchartMap extends React.Component {',
					'constructor(props) {',
					'super(props)',
					'this.setPieOption = this.setPieOption.bind(this)',
					'this.initPie = this.initPie.bind(this)',
					'}',
					'initPie() {',
					'const { data } = this.props',
					'let myChart = echarts.init(this.refs.AreaEcharts)',
					'let options = this.setPieOption(data)',
					'myChart.setOption(options)',
					'// 检测页面尺寸变化',
					'window.addEventListener("resize", function () {',
					'myChart.resize();',
					'})',
					'}',
					'componentDidMount',
					'this.initPie()',
					'}',
					'componentDidUpdate() {',
					'this.initPie()',
					'}',
					'render() {',
					'return (',
					'<div className="pie-react">',
					')',
					'}',
					'setPieOption(data) {',
					'return {',
					"_backgroundColor: '#FFFFFF',",
					'tooltip : {',
					"trigger: 'item'",
					'},',
					'series: [{',
					'// 此处设置图表的一些属性方法直接从官方demo复制粘贴过来即可',
					'}]',
					'}',
					'}',
					'}'
				],
				textindentArr: [
					0, 0, 0, 0, 0, 2, 4, 4, 4, 2, 0, 2, 2, 2, 2, 2, 2, 4, 2, 0, 0, 2, 0, 0, 2, 0, 0, 2, 4, 2, 0, 0, 2, 4, 4, 6, 4, 4, 6, 4, 0, 0, 0
				]
			},
			{
				name: 'Echarts在ReactNative中的安装和使用方法',
				parameterArr: [
					'1. npm install --save native-echarts',
				],
				funcArr: [
					"import React, { Component } from 'react';",
					"import Echarts from 'native-echarts';",
					"import { Platform } from 'react-native';",
					'export default class PieEcharts extends Component {',
					'constructor(props) {',
					'super(props);',
					'this.state = {',
					'}',
					'}',
					'shouldComponentUpdate(nextProps, nextState) {',
					'for (let i = 0; i < nextProps.data; i++) {',
					'if (nextProps.data[i].value !== this.props.data[i].value) {',
					'return true',
					'}',
					'}',
					'if (nextProps.backgroundColor !== this.props.backgroundColor) {',
					'return true',
					'}',
					'return false',
					'}',
					'render() {',
					'const option = {',
					"backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : 'rgb(27,27,27)',",
					'tooltip: {',
					"trigger: 'axis',",
					'formatter: "{a} {b}: {c} ({d}%)"',
					'},',
					'}',
					']',
					'};',
					'return (',
					'<Echarts',
					'option={option}',
					"height={Platform.OS === 'android' ? 150 : 150} />",
					');',
					'}}'
				],
				textindentArr: [
					0, 0, 0, 0, 2, 4, 4, 2, 0, 0, 2, 4, 6, 4, 2, 2, 6, 2, 2, 0, 0, 2, 2, 2, 4, 4, 2, 0, 0, 0, 2, 4, 4, 4, 0
				]
			},
		]
	}
]

export {
	meanuList,
	componentList
}