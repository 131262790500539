const Images = {
	right: require('./right.png'),
	down: require('./down.png'),
	sun: require('./sun.png'),
	black: require('./black.png'),


	// wui
	wregister: require('./wui/wregister.png'),
	wlogin: require('./wui/wlogin.png'),
	wheader: require('./wui/wheader.png'),
	wtitle: require('./wui/wtitle.png'),
	wsearch: require('./wui/wsearch.png'),
	wtab: require('./wui/wtab.png'),
	wtoptitle: require('./wui/wtoptitle.png'),
	wcard: require('./wui/wcard.png'),
	wimagecard: require('./wui/wimagecard.png'),
	wcardone: require('./wui/wcardone.png'),
	warticle: require('./wui/warticle.png'),
	wcomment: require('./wui/wcomment.png'),
	wloading: require('./wui/wloading.png'),
	wtips: require('./wui/wtips.png'),
	wtimeselect: require('./wui/wtimeselect.png'),
	wdate: require('./wui/wdate.png'),
	wdatepicker: require('./wui/wdatepicker.png'),
	wcategory: require('./wui/wcategory.png'),
	wswitch: require('./wui/wswitch.png'),
	whelp: require('./wui/whelp.png'),
	wverificationcode: require('./wui/wverificationcode.png'),
	wquantity: require('./wui/wquantity.png'),
	wspinner: require('./wui/wspinner.png'),
	wmessage: require('./wui/wmessage.png'),
	wcode: require('./wui/wcode.png'),
	wpsbox: require('./wui/wpsbox.png'),
	wprogress: require('./wui/wprogress.png'),
}

export default Images